<template>
  <div id="AppointmentSearch">
    <div class="vx-row">
      <feather-icon @click="back()" icon="ChevronLeftIcon" style="width: 30px">
      </feather-icon>
      <p class="text-xl">
        {{ $t("OnlineAppointment") }}
      </p>
    </div>
    <vx-card
      style="background-color: #7f94b5; margin-bottom: 1%"
   
    >
      <div class="vx-row">
        <h1>Patient Name : {{ this.patientObj.Name }} /</h1>
        <h1>Patient Email : {{ this.patientObj.Email }}</h1>
      </div>
    </vx-card>

    <div id="searchdiv" hidden>
      <div class="vx-row">
        <div class="vx-col lg:w-1/5 sm:w-full w-full mt-5">
          <v-select
            class="w-full"
            label="Name"
            v-model="search.SpecialtyID"
            :options="specialties"
            :placeholder="$t('Specialty')"
            :reduce="(ID) => ID.ID"
          />
        </div>
        <div class="vx-col lg:w-1/5 w-full mt-5">
          <v-select
            class="w-full"
            label="Name"
            v-model="search.CountryID"
            :options="topDestinations"
            :reduce="(ID) => ID.ID"
            :placeholder="$t('Destination')"
            @input="GetCitiesBycountry()"
          />
        </div>
        <div class="vx-col lg:w-1/5 w-full mt-5">
          <v-select
            class="w-full"
            label="Name"
            v-model="search.CityID"
            :options="cities"
            :reduce="(ID) => ID.ID"
            :placeholder="$t('City')"
          />
        </div>
        <div class="vx-col lg:w-1/5 w-full mt-5">
          <v-select
            class="w-full"
            label="Name"
            v-model="search.DoctorID"
            :options="doctors"
            :reduce="(ID) => ID.ID"
            :placeholder="$t('Doctors')"
          />
        </div>
        <vs-button
          class="mt-5"
          @click="searchDoctor()"
          color="white"
          text-color="rgb(69, 74, 98)"
          radius
          icon-pack="icon icon-search"
          icon="feather"
        ></vs-button>
      </div>
    </div>
    <div v-if="searchDoctors.length == 0">
      <div class="vx-row">
        <div class="vx-col lg:w-1/2 w-full">
          <h2 class="header mt-10">{{ $t("TopDestinations") }}</h2>
          <vs-row vs-type="flex" vs-justify="center" vs-w="12" class="mt-5">
            <vs-col
              v-for="item in topDestinationsPage"
              :key="item.name"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-lg="6"
              vs-md="6"
              vs-sm="12"
              vs-xs="12"
            >
              <div v-on:click="selectDestination(item)" class="handclick mt-2">
                <div style="height: 120px">
                  <div class="imageDiv">
                   
                    <imageLazy
                      :imageStyle="{ 'border-radius': '10px','height': '120px' }"
                      :imageClass="'w-full'"
                      :src="baseURL + item.ImagePath"
                      placeHolderType="country"
                    />
                  </div>
                  <div
                    class="imageDiv shadowDiv"
                    v-if="item.ID != search.CountryID"
                  ></div>
                </div>
                <h4 class="text-center primarycolor fontBook">
                  {{ item.Name }}
                </h4>
              </div>
            </vs-col>
          </vs-row>

          <h2 class="header mt-5">{{ $t("Cities") }}</h2>
          <div
            responsive
            class="flex overflow-x-scroll py-10 items hide-scroll-bar"
          >
            <section
              class="inline-block px-1"
              v-for="item in cities"
              :key="item.ID"
            >
              <div v-on:click="setSelectedCity(item.ID)" class="handclick">
                <div style="height: 120px">
                  <div class="imageDiv">
                    <img
                      alt=""
                      style="height: 115px"
                      :src="baseURL + item.ImagePath"
                    />
                  </div>
                  <div
                    class="imageDiv CityshadowDiv"
                    v-if="item.ID != search.CityID"
                  ></div>
                </div>
                <h4 class="text-center primarycolor fontBook">
                  {{ item.Name }}
                </h4>
              </div>
            </section>
          </div>
        </div>
        <div class="vx-col lg:w-1/2 w-full mt-10">
          <div class="vx-row ml-10 mr-10">
            <div class="vx-col lg:w-1/2 sm:w-full w-full mt-5">
              <vs-card>
                <vs-list>
                  <vs-list-header
                    class="ListHeader"
                    :title="$t('MainSpecialty')"
                  ></vs-list-header>
                  <div class="listScroll">
                    <vs-list-item
                      class="listItem"
                      :style="[
                        item.ID == search.ParentSpecialtyID
                          ? { 'background-color': '#A4D1EF' }
                          : {},
                      ]"
                      v-for="item in parentSpecialties"
                      :key="item.ID"
                    >
                      <div
                        @click="
                          search.ParentSpecialtyID = item.ID;
                          ChangeParentSpeciality();
                        "
                      >
                        <h6>{{ item.Name }}</h6>
                      </div>
                    </vs-list-item>
                  </div>
                </vs-list>
              </vs-card>
            </div>

            <div class="vx-col lg:w-1/2 sm:w-full w-full mt-5">
              <vs-card>
                <vs-list>
                  <vs-list-header
                    class="ListHeader"
                    :title="$t('Specialty')"
                  ></vs-list-header>
                  <div class="listScroll">
                    <vs-list-item
                      :style="[
                        item.ID == search.SpecialtyID
                          ? { 'background-color': '#A4D1EF' }
                          : {},
                      ]"
                      class="listItem"
                      v-for="item in childSpecialties"
                      :key="item.ID"
                    >
                      <div @click="search.SpecialtyID = item.ID">
                        <h6>{{ item.Name }}</h6>
                      </div>
                    </vs-list-item>
                  </div>
                </vs-list>
              </vs-card>
            </div>
          </div>

          <div class="vx-row ml-10 mr-10">
            <div class="vx-col lg:w-1/2 sm:w-full w-full mt-5">
              <vs-card>
                <vs-list>
                  <vs-list-header
                    class="ListHeader"
                    :title="$t('Destinations')"
                  ></vs-list-header>
                  <div class="listScroll">
                    <vs-list-item
                      class="listItem"
                      :style="[
                        item.ID == search.CountryID
                          ? { 'background-color': '#A4D1EF' }
                          : {},
                      ]"
                      v-for="item in topDestinations"
                      :key="item.ID"
                    >
                      <div @click="selectDestination(item)">
                        <h6>{{ item.Name }}</h6>
                      </div>
                    </vs-list-item>
                  </div>
                </vs-list>
              </vs-card>
            </div>

            <div class="vx-col lg:w-1/2 sm:w-full w-full mt-5">
              <vs-card>
                <vs-list>
                  <vs-list-header
                    class="ListHeader"
                    :title="$t('Cities')"
                  ></vs-list-header>
                  <div class="listScroll">
                    <vs-list-item
                      :style="[
                        item.ID == search.CityID
                          ? { 'background-color': '#A4D1EF' }
                          : {},
                      ]"
                      class="listItem"
                      v-for="item in cities"
                      :key="item.ID"
                    >
                      <div @click="search.CityID = item.ID">
                        <h6>{{ item.Name }}</h6>
                      </div>
                    </vs-list-item>
                  </div>
                </vs-list>
              </vs-card>
            </div>
          </div>
        </div>
      </div>

      <div class="vx-row">
        <div class="vx-col lg:w-3/4"></div>
        <vs-button
          icon-pack="icon icon-clear"
          icon-after="true"
          icon="feather"
          color="red"
          class="ml-2 mr-2"
          text-color="rgb(255, 255, 255)"
          @click="clearSearch()"
        >
          {{ $t("Clear") }}
        </vs-button>
        <vs-button
          icon-pack="icon icon-search"
          icon-after="true"
          icon="feather"
          color="rgb(0, 156, 255)"
          text-color="rgb(255, 255, 255)"
          @click="searchDoctor()"
        >
          {{ $t("Search") }}
        </vs-button>
      </div>
    </div>
    <div style="margin-top: 5rem">
      <doctor-list
        class="mt-5"
        v-for="item in searchDoctors"
        :key="item.ID"
        :doctor="item"
      />
    </div>
  </div>
</template>
<script>
import moduleSpecialty from "@/store/settings/specialty/moduleSpecialty.js";
import moduleCountry from "@/store/settings/country/moduleCountry.js";
import moduleDoctor from "@/store/doctor/moduleDoctor.js";
import modulePatient from "@/store/Patient/modulePatient.js";
import DoctorList from "./DoctorList.vue";
import moduleCity from "@/store/settings/city/moduleCity.js";
import { domain } from "@/gloabelConstant.js";
import imageLazy from "@/components/image_lazy.vue";
export default {
  components: {
    DoctorList,
    imageLazy
  },
  data() {
    return {
      search: {
        SpecialtyID: "",
        CountryID: "",
        CityID: "",
      },
      PatientID: "",
      patientObj: "",
      baseURL: domain,
      CurrentPage: 1,
      CountPerPage: 4,
    };
  },
  methods: {
    clearSearch() {
      this.search.SpecialtyID = "";
      this.search.CityID = "";
      this.search.CountryID = "";
      this.search.ParentSpecialtyID = "";
    },
    setSelectedCity(Id) {
      this.search.CityID = Id;
    },
    GoBack() {
      this.CurrentPage = this.CurrentPage - 1;
    },

    GoNext() {
      this.CurrentPage = this.CurrentPage + 1;
    },
    back() {
      if (this.searchDoctors.length == 0) this.$router.go(-1);
      else this.$store.commit("DoctorList/SET_SearchDoctor", []);
    },
    ChangeParentSpeciality() {
      this.search.SpecialtyID = null;
      this.$store.dispatch(
        "SpecialtyList/GetSpecialtyByParentSpecialtyID",
        this.search.ParentSpecialtyID
      );
    },
    searchDoctor() {
      debugger;
      this.$store.dispatch("DoctorList/SearchDoctors", this.search);
    },
    selectDestination(destination) {
      this.search.CountryID = destination.ID;
      this.GetCitiesBycountry();
    },
    selectCity(city) {
      this.search.CityID = city.ID;
    },
    GetCitiesBycountry() {
      this.search.CityID = null;
      this.$store.dispatch(
        "CityList/GetAllCitiesByCountryID",
        this.search.CountryID
      );
    },
    getPatientData() {},
  },
  computed: {
    topDestinationsPage() {
      return this.$store.state.CountryList.topDestinations.slice(
        (this.CurrentPage - 1) * this.CountPerPage,
        (this.CurrentPage - 1) * this.CountPerPage + this.CountPerPage
      );
    },
    searchDoctors() {
      return this.$store.state.DoctorList.searchDoctors;
    },
    doctors() {
      return this.$store.state.DoctorList.doctors;
    },
    specialties() {
      return this.$store.state.SpecialtyList.specialties;
    },
    parentSpecialties() {
      return this.$store.state.SpecialtyList.parentSpecialties;
    },
    childSpecialties() {
      return this.$store.state.SpecialtyList.childSpecialties;
    },
    doctorCountries() {
      return this.$store.state.CountryList.doctorCountries;
    },
    topDestinations() {
      return this.$store.state.CountryList.topDestinations;
    },
    cities() {
      if (this.$store.state.CityList.cities)
        return this.$store.state.CityList.cities;
      else return [{}];
    },
  },
  created() {
    debugger;
    if (!moduleSpecialty.isRegistered) {
      debugger;
      this.$store.registerModule("SpecialtyList", moduleSpecialty);
      moduleSpecialty.isRegistered = true;
    }
    if (!moduleCountry.isRegistered) {
      debugger;
      this.$store.registerModule("CountryList", moduleCountry);
      moduleCountry.isRegistered = true;
    }
    debugger;
    if (!moduleDoctor.isRegistered) {
      debugger;
      this.$store.registerModule("DoctorList", moduleDoctor);
      moduleDoctor.isRegistered = true;
    }
    debugger;
    if (!moduleCity.isRegistered) {
      debugger;
      this.$store.registerModule("CityList", moduleCity);
      moduleCity.isRegistered = true;
    }
    debugger;

    if (!modulePatient.isRegistered) {
       this.$store.registerModule("patientList", modulePatient);
       modulePatient.isRegistered = true;
     }

     this.$store
       .dispatch("patientList/GetPatient", this.$route.params.ID)
       .then((res) => {
         debugger;
         this.patientObj = res.data.Data;
         debugger;
       })
       .catch((err) => {
         debugger;
         this.$vs.loading.close();
       });
    debugger;
    this.$store.commit("DoctorList/SET_SearchDoctor", []);

    this.$store.commit("DoctorList/SET_Doctor", []);
    this.$store.dispatch("CountryList/GetDoctorCountry");
    this.$store.dispatch("CountryList/GetTopDestinations");
    debugger;
    this.$store.dispatch("SpecialtyList/GetAllSpecialties");
    this.$store.dispatch("SpecialtyList/GetParentSpecialty");
    debugger;
    this.$store.dispatch("DoctorList/GetAllDoctors", {});
    debugger;
    const CountryID = this.$route.params.CountryID;
    const SpecialtyID = this.$route.params.SpecialtyID;
    const DoctorID = this.$route.params.DoctorID;
    this.PatientID = this.$route.params.ID;

    debugger;
    if (
      CountryID != undefined ||
      SpecialtyID != undefined ||
      DoctorID != undefined
    ) {
      if (SpecialtyID != undefined)
        this.search.SpecialtyID = parseInt(SpecialtyID);
      if (CountryID != undefined) this.search.CountryID = parseInt(CountryID);
      if (DoctorID != undefined) this.search.DoctorID = parseInt(DoctorID);

      this.searchDoctor();
    }

    // if (!modulePatient.isRegistered) {
    //   this.$store.registerModule("patientList", modulePatient);
    //   modulePatient.isRegistered = true;
    // }
    // // var vm = this;
    // const ID = this.$route.params.ID;
    // if (ID != undefined) {
    //   this.$store.dispatch("patientList/GetPatient", ID).then((res) => {
    //     this.patientObj = res.data.Data;
    //   });
    // }

    debugger;
  },
};
</script>
<style>
#AppointmentSearch .vs-list--slot {
  margin: inherit;
  width: 100%;
}
#AppointmentSearch .vs-list {
  padding: 0px;
}
#AppointmentSearch .vs-card--content {
  padding: 0px;
}
#AppointmentSearch .listScroll {
  height: 170px;
  overflow-y: scroll;
}
#AppointmentSearch .ListHeader {
  height: 46px;
  background: #009cff;
  padding: 10px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
  color: white;
}
#AppointmentSearch .listItem {
  height: 40px;
  cursor: pointer;
  padding: 10px;
}

#searchdiv {
  margin: -56px 31px;
}
.vs__dropdown-toggle {
  border-radius: 9px;
  background: white;
  border: 2px solid #454a62;
}
</style>

<style>
.imageDiv {
  display: block;
}

#AppointmentSearch .shadowDiv {
  position: relative;
  height: 115px;
  bottom: 115px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 32px;
}
#AppointmentSearch .imageCityDiv {
  display: block;
}

#AppointmentSearch .CityshadowDiv {
  position: relative;
  height: 115px;
  bottom: 115px;
  left: 0px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 19px;
}

::-webkit-scrollbar {
  height: 5px;
  width: 2px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #f1f1f1;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #888;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #555;
}
</style>
