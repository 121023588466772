<!-- =========================================================================================
    File Name: Login.vue
    Description: Login Page
    ----------------------------------------------------------------------------------------
    Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
      Author: Pixinvent
    Author URL: http://www.themeforest.net/user/pixinvent
========================================================================================== -->

<template>
  <div class="flex succesuss">
    <div slot="no-body">
      <div class="justify-center items-center customBG">
        <div class="d-theme-dark-bg" style="border-radius: 15px">
          <div class="p-5">
           <div class="logo ">
      <img width="200" height="50" class="TextLogo" alt="" src="@/assets/images/logo.png" />
     </div>
    <div class="mt-4 text-center">
       <h5 class=" mt-2"  style="font-color:black">{{Message}} </h5>

    
     </div>
  
        
            <div style="text-align: center">
              <vs-button
                style="width: 60%; margin-top: 2rem"
                @click="$emit('CloseSeccussPopup')"
                >{{ $t("ok") }}</vs-button
              >
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  data() {
    return {
      
    };
  },
 props: {
    Message:{
       type: String,
      default: () => "",
    }
  },
  components: {},
  computed: {

  },
  created() {

  },
};
</script>

<style lang="scss">
.succesuss {
  width: 30% !important;
  margin: auto;
}
.logo{


background: #FFFFFF 0% 0% no-repeat padding-box;
border-radius: 20px;
opacity: 1;
text-align: center;
}
.P-Text {
  color: #004477;
}
</style>


