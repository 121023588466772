<template>
  <div id="DorList">
    <vs-card>
      <div class="vx-col">
        <div class="vx-row">
          <div class="vx-col lg:w-1/5">
           
            <imageLazy
                      :imageStyle="{ 'border-radius': '10px',float: 'right','height':'120px', }"
                      :imageClass="'doctorImage'"
                      :src="baseURL + doctor.ProfileImagePath"
                      placeHolderType="doctor"
                    />
          </div>
          <div class="vx-col lg:w-1/3">
            <h4 class="cursor-pointer" style="text-decoration: underline">
              {{ $t("Dr") }}. {{ doctor.Name }}
            </h4>
            <div class="mt-1 fontSizetext">
              {{ $t("Destination") }}:<span
                class="fontMedium fontbold m-1"
                v-if="doctor.Country"
                >{{ doctor.Country.Name }}</span
              >
            </div>
            <div class="mt-1 fontSizetext">
              {{ $t("Speciality") }}:<span
                class="fontMedium fontbold m-1"
                style="text-align: justify"
                >{{ doctor.Specialties }}</span
              >
            </div>
            <div class="mt-1 fontSizetext">
              {{ $t("Title") }}:<span class="fontMedium fontbold m-1">{{
                doctor.Title
              }}</span>
            </div>
            <div v-if="showBiography" class="mt-1 fontSizetext">
              {{ $t("Certificates") }}:<span
                class="fontMedium fontbold m-1 fontSizetext"
                >{{ doctor.Educations }}</span
              >
            </div>
            <div v-if="showBiography" class="mt-1 fontSizetext">
              {{ $t("Biography") }}:<span class="fontMedium fontbold m-1 fontSizetext">{{
                doctor.Bio
              }}</span>
            </div>
          </div>
          <div class="vx-col lg:w-1/3" v-if="!showAppointments">
            <div class="vx-row m-1">
              <div class="lg:w-1/2 mt-6">
                <vs-button
                  class="vx-col"
                  text-color="rgba(104, 104, 104, 1)"
                  color="white"
                  @click="viewBiography()"
                  ><u>{{ $t("Biography") }}</u></vs-button
                >
              </div>
              <div class="lg:w-1/2">
                <div class="ml-5 mt-1 fontSizetext">
                  {{ $t("Price") }}:<span
                    class="ml-1"
                    style="font-size: 20px; color: black"
                    >{{ doctor.PriceSessionInUSD }} $</span
                  >
                </div>
                <vs-button
                  class="vx-col mt-1"
                  @click="viewAppointment()"
                  v-if="doctor.HaseAppointemts == true"
                  >{{ $t("Online Appointment") }}</vs-button
                >
                <vs-button class="vx-col mt-1" @click="viewAppointment()" v-else>{{
                  $t("Request Appointment")
                }}</vs-button>
              </div>
            </div>
          </div>
        </div>
        <div class="vx-row mr-5 ml-5 vx-row w-full">
          <div class="lg:w-1/2 mt-1 mb-2">
            <v-date-picker
              @update:from-page="pageChange"
              :locale="$vs.rtl ? 'ar' : 'en'"
              :attributes="attrs"
              :min-date="minDate"
              style="width: 100%"
              v-if="showAppointments"
              v-model="appointmentDate"
              @input="getAppointmentSessions"
            />
          </div>
          <div class="lg:w-1/2">
            <h5 class="ml-5" id="SessionSlot" v-if="showAppointments">
              {{ $t("Select A Time For") }} {{ appointmentDateStrig }} :
            </h5>
            <!-- <h1
              class="m-5"
              id="SessionSlot"
              style="color: orange"
              v-if="sessions.length == 0 && showAppointments"
            >
              {{ $t("PleaseSelectATimeFor") }}
            </h1> -->

            <div v-if="showAppointments" class="vx-row ml-5">
              <div v-for="item in sessions" :key="item.name" class="lg:w-1/4 mt-5 m-1">
                <div
                  v-on:click="
                    selectSession(item);
                    isSelected = !isSelected;
                  "
                >
                  <vx-card
                    class="p-0 m-0"
                    :style="[
                      !item.IsAvailable
                        ? { 'background-color': 'gray' }
                        : item.ID == Session.ID && isSelected
                        ? {
                            'background-color': '#004477',
                            'font-color': 'white',
                          }
                        : item.IsBlocked
                        ? { 'background-color': 'red', 'font-color': 'white' }
                        : {},
                    ]"
                  >
                    <p
                      v-if="item.DateFrom != null && item.DateTo != null"
                      :style="[
                        item.ID == Session.ID && isSelected
                          ? { color: 'white' }
                          : item.IsBlocked
                          ? { color: 'white' }
                          : !item.IsAvailable
                          ? { color: 'white' }
                          : {},
                      ]"
                      class="text-center fontBook"
                    >
                      {{ item.DateFrom.slice(11, 16) }} -
                      {{ item.DateTo.slice(11, 16) }}
                    </p>
                  </vx-card>
                </div>
              </div>
            </div>

            <div class="vx-row" v-if="Session.ID > 0">
              <div class="vx-col w-full">
                <div class="mt-8 mb-5 mr-12 flex flex-wrap items-center justify-end">
                  <vs-button :disabled="!isSelected" @click="chooseSession()"
                    >{{ $t("Procced") }}
                  </vs-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </vs-card>

    <vs-popup
      title="Patient Reservation"
      :active.sync="showSuccessPop"
      v-if="showSuccessPop"
    >
      <div class="d-theme-dark-bg" style="border-radius: 15px">
        <div>
          <div class="logo">
            <img
              width="200"
              height="50"
              class="TextLogo"
              alt=""
              src="@/assets/images/logo.png"
            />
          </div>
          <div class="mt-4 text-center">
            <h5 class="mt-2" style="font-color: black">Patient Reservation Completed</h5>
          </div>

          <div style="text-align: center">
            <vs-button style="width: 40%" @click="CloseSeccussPopup()">{{
              $t("ok")
            }}</vs-button>
          </div>
        </div>
      </div>
    </vs-popup>
  </div>
</template>
<script>
import Datepicker from "vuejs-datepicker";
import moment from "moment";
import { domain } from "@/gloabelConstant.js";
import SuccessPopup from "@/views/pages/register/SuccessPopup.vue";

import imageLazy from "@/components/image_lazy.vue";
export default {
  data() {
    return {
      minDate: new Date(),
      isSelected: false,
      baseURL: domain,
      sessionModel: {},
      patientNote: "",
      updatedPatientSession: {},
      showSuccessPop: false,
      showBiography: false,
      showAppointments: false,
      appointmentDate: new Date(),
      appointmentDateStrig: "",
      sessions: [],
      Session: {},

      monthSessions: [],
      paymentStatus: false,
      search: {},
    };
  },
  props: {
    doctor: {
      type: Object,
      default: () => {},
    },
  },
  computed: {
    attrs() {
      return [
        {
          bar: "green",
          dates: this.monthSessions.map((ob) => ob.DateFrom),
        },
      ];
    },
  },
  components: {
    Datepicker,
    SuccessPopup,
    imageLazy
  },
  methods: {
    CloseSeccussPopup() {
      this.showSuccessPop = false;
    },

    pageChange(obj) {
      debugger;
      this.sessions = [];
      debugger;
      var search = {
        DateFrom: obj.year + "/" + obj.month + "/1",
        To: new Date(obj.year, obj.month, 0),
      };
      search.To.setDate(search.To.getDate() + 1);
      search.DateTo = moment(search.To).format("LL");

      search.doctorID = this.doctor.ID;
      this.search = search;
      this.$store.dispatch("DoctorList/SearchDoctorMonthSessions", search).then((res) => {
        this.monthSessions = res.data.Data;
      });
    },

    changeFormatedDate(date) {
      return moment(date).format("LL");
    },
    Proceed() {
      debugger;
      var model = {};
      model.PatientID = parseInt(this.$route.params.ID);
      model.DoctorSessionID = this.sessionModel.SessionID;
      model.DoctorID = this.sessionModel.DoctorID;
      model.MarkupFees = this.sessionModel.MarkupFees;
      model.PaymentStatusID = 1; //pending
      model.StatusID = 1;
      this.$vs.loading();
      this.$store
        .dispatch("DoctorList/AddPatientReservationSession", model)
        .then((res) => {
          if (res.status == 200) {
            this.$vs.loading.close();
            this.updatedPatientSession = res.data.Data;

            this.showSuccessPop = true;

            this.isSelected = false;
            this.getAppointmentSessions();
          }
        })
        .catch(() => {
          this.$vs.loading.close();
        });
    },

    chooseSession() {
      debugger;
      if (this.Session.IsBlocked == true) {
        this.$vs.notify({
          color: "danger",
          position: "top-center",
          title: this.$t("Session Blocked"),
          text: this.$t("this Session is  Blocked choice another"),
        });
      } else if (!this.Session.IsAvailable == true) {
        this.$vs.notify({
          color: "warning",
          position: "top-center",
          title: this.$t("Session Selected"),
          text: this.$t("this Session is not IsAvailable choice another"),
        });
      } else if (this.Session.IsAvailable == true) {
        debugger;
        this.sessionModel.SessionID = this.Session.ID;
        this.sessionModel.DoctorID = this.Session.DoctorID;
        this.sessionModel.DoctorName = this.Session.Doctor.Name;

        this.sessionModel.SessionDate = this.Session.DateFrom;
        this.sessionModel.TimeFrom = this.Session.DateFrom.slice(11, 16);
        this.sessionModel.TimeTo = this.Session.DateTo.slice(11, 16);
        this.sessionModel.doctorImg = this.doctor.ProfileImagePath;
        this.sessionModel.Country = this.doctor.Country.Name;
        this.sessionModel.Specialties = this.doctor.Specialties;
        this.sessionModel.Educations = this.doctor.Educations;
        this.sessionModel.MarkupFees = this.doctor.MarkupFees;
        this.From = new Date("01/01/2007 " + this.Session.DateFrom.slice(11, 16));
        this.To = new Date("01/01/2007 " + this.Session.DateTo.slice(11, 16));

        this.sessionModel.DurationInMinutes = parseInt(
          (Math.abs(new Date(this.To.getTime()) - new Date(this.From.getTime())) /
            (1000 * 60)) %
            60
        );
        this.Proceed();
      }
    },

    selectSession(session) {
      this.Session = session;
    },

    viewAppointment() {
      debugger;
      this.showAppointments = !this.showAppointments;
      if (this.showAppointments) {
        this.appointmentDate = new Date();
        this.getAppointmentSessions();
      }
    },
    addHours(date, h) {
      if (typeof date === "string") date = new Date(date);
      date.setTime(date.getTime() + h * 60 * 60 * 1000);
      return date;
    },
    viewBiography() {
      this.showBiography = !this.showBiography;
    },
    getAppointmentSessions() {
      debugger;
      this.sessions = [];
      this.Session = {};
      var dateNow = new Date();
      //this.appointmentDate = this.addHours(this.appointmentDate, 1);
      // if (dateNow > this.appointmentDate) {
      //   window.PastDate();
      //   this.appointmentDate = new Date();
      // } else
      {
        debugger;
        this.appointmentDateStrig = moment(this.appointmentDate).format("LL");
        // var searchAppointment = {};
        this.search.DoctorID = this.doctor.ID;
        // searchAppointment.DoctorID = this.doctor.ID;

        // this.appointmentDate = new Date(
        //   this.appointmentDate.setHours(0, 0, 0, 0)
        // );
        // this.appointmentDate = this.addHours(this.appointmentDate, 21);

        this.search.DayUTC = this.appointmentDateStrig;
        this.sessions = [];
        this.$vs.loading();
        this.$store
          .dispatch("DoctorList/SearchDoctorSessions", this.search)
          .then((res) => {
            this.sessions = res.data.Data;
            this.$vs.loading.close();
            debugger;
          })
          .catch(() => {
            this.$vs.loading.close();
          });
      }
    },
  },
  mounted() {},
};
</script>
<style>
.doctorImage {
  width: 100px;
  height: 100px;
  text-align: end;
}
.CurrentPageStatment {
  top: 414px;
  left: 600px;
  width: 204px;
  height: 95px;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  letter-spacing: NaNpx;
  color: #ffffff;
  opacity: 1;
}
.OtherPageStatment {
  top: 532px;
  left: 600px;
  width: 215px;
  height: 99px;
  color: var(--unnamed-color-ffffff);
  text-align: left;
  letter-spacing: NaNpx;
  color: #ffffff;
  opacity: 0.3;
}
.text-1xl {
  color: #393939;

  font-size: 1rem;
  letter-spacing: 0px;

  opacity: 0.5;
}
.fontSizetext {
  font-size: 15px !important;
}

/* .vs-popup--content {
  height: calc(100vh - 100px);
} */
#DorList .con-vs-popup {
  width: 100% !important;
  /* height: 100vh; */
}
#DorList .vs-popup {
  width: 30% !important;
  height: 38vh;
}
/* .custCardPad{
   padding: 1.5rem 0 1.5rem 0; 
  padding-top: 0.5rem;
  padding-right: 0;
  padding-bottom: 0.5rem;
  padding-left: 0;
} */
#DorList .vx-card {
  padding: 0px !important;
}
#DorList .vx-card .vx-card__collapsible-content .vx-card__body {
  padding: 1rem 0 1rem 0 !important;
}
</style>
